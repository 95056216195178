import ProductDetail from '../ProductDetail/ProductDetail';
import React, { useContext, useState, useEffect } from 'react';
import constant from '../../constant.js'


const Product = (props) => {
	const [isLoading, setIsLoading] = useState(true);
	const [product, setProduct] = useState([]);

	const getProductPrice = (product) => {
	    // Extract the price from the first parameter or default to product price
	    return product.parameters.length > 0 ? product.parameters[0].price : product.price;
	  };

	useEffect(() => {
	    const fetchProduct = async () => {
	      try {
	        const response = await fetch(`${constant.DOMAIN}/products/${props.match.params.id}`);
	        if (!response.ok) {
	          throw new Error(`HTTP error! status: ${response.status}`);
	        }
	        const data = await response.json();
	        setProduct({ ...data, price: getProductPrice(data) });
	        setIsLoading(false); 
	      } catch (error) {
	        setIsLoading(false); 
	      }
	    };



	    fetchProduct();
	  }, []);

	const handleBackToProducts = () => {
	    setProduct(null);
	  };

	return (
		<div className="category-product">
			{
			  isLoading ?  
			  	<div>Loading...</div>
		      : product ?
		        <ProductDetail product={product} onBack={handleBackToProducts} />
		      : null
			}
		</div>
	)

}

export default Product;